var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"admin-list"}},[_c('user-add'),_c('v-card',[_c('v-card-title',[_vm._v(" Admins list ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"admin-status-filter",attrs:{"placeholder":"Select Status","items":_vm.statusOptions(),"item-text":"title","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.logic.statusFilter.value),callback:function ($$v) {_vm.$set(_vm.logic.statusFilter, "value", $$v)},expression:"logic.statusFilter.value"}})],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"admin-search-query user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.logic.searchQuery.value),callback:function ($$v) {_vm.$set(_vm.logic.searchQuery, "value", $$v)},expression:"logic.searchQuery.value"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"add-new-admin mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.logic.openUserAddModal()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Admin")])],1)],1)],1),_c('v-data-table',{attrs:{"options":_vm.logic.options.value,"headers":_vm.logic.tableColumns,"items":_vm.logic.users.value,"item-class":function () { return 'user-item'; },"server-items-length":_vm.logic.countUsers.value,"loading":_vm.logic.loading.value},on:{"update:options":function($event){return _vm.$set(_vm.logic.options, "value", $event)}},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullName)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"admin-name text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'admin-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"admin-item-email"},[_vm._v(_vm._s(item.email))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.status,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "status", $event)},"update:return-value":function($event){return _vm.$set(item, "status", $event)},"save":function($event){return _vm.logic.updateStatus(item.id, _vm.logic.newStatus.value)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v("Update Status")]),_c('v-select',{attrs:{"items":_vm.statusOptions(),"item-text":"title","item-value":"value","label":"Status"},model:{value:(_vm.logic.newStatus.value),callback:function ($$v) {_vm.$set(_vm.logic.newStatus, "value", $$v)},expression:"logic.newStatus.value"}})]},proxy:true}],null,true)},[_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"admin-list-item-show-more-actions",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"admin-list-item-detail",attrs:{"to":{ name: 'admin-view', params: { id: item.id } }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c('v-list-item',{staticClass:"admin-list-item-delete",on:{"click":function($event){return _vm.logic.deleteUser(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1),_c('v-list-item',{staticClass:"admin-list-item-reset-2fa",on:{"click":function($event){return _vm.logic.reset2fa(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiOnepassword)+" ")]),_c('span',[_vm._v("Reset 2fa")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }